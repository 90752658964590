import { ReactNode, Suspense } from "react";
import { ErrorFeedback, LoadingFallback } from "@/components";

export const QuerySuspense = ({ children }: { children?: ReactNode }) => {
  return (
    <ErrorFeedback>
      <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
    </ErrorFeedback>
  );
};
