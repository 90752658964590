import { useState } from "react";
import { Command, Popover } from "@/components";
import { useGetBanks } from "@/services/banks";
import { Bank } from "@/services/banks/types";
import Icon from "@hyperlocal/vital-icons";
import { TextField } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";

interface SelectBankComboBoxProps {
  onBankSelect: (bank: Bank) => void;
  error?: boolean;
  disabled?: boolean;
  bank?: Bank;
}

export const SelectBankComboBox = ({
  onBankSelect,
  error,
  disabled,
  bank,
}: SelectBankComboBoxProps) => {
  const [bankSelected, setBankSelected] = useState<Bank>(bank);
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetBanks();

  const isDisabled = isLoading || disabled;

  const handleSelectItem = (bank: Bank) => {
    setBankSelected(bank);
    onBankSelect(bank);
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild className="flex w-full">
        <button
          className={twMerge(
            "flex justify-between rounded-xs border border-neutral-main p-[16px] text-neutral-dark",
            error && "border-status-error-default",
            isDisabled && "bg-neutral-lighter",
          )}
          disabled={isDisabled}
        >
          {!bankSelected ? (
            <>
              Buscar instituição
              {isLoading ? (
                <Icon
                  name="ArrowRefreshLine"
                  className="size-6 animate-spin fill-current"
                />
              ) : (
                <Icon name="SearchNormal" className="size-6 fill-current" />
              )}
            </>
          ) : (
            <>
              {bankSelected.bankCode} - {bankSelected.shortBankName}
            </>
          )}
        </button>
      </Popover.Trigger>
      <TextField.Helper className="text-status-error-default">
        {!!error && "Instituição obrigatória"}
      </TextField.Helper>

      <Popover.Content>
        <Command.Root value={bankSelected?.bankCode}>
          <Command.Input placeholder="Digite aqui" />
          <Command.List>
            <Command.Empty>Instituição não encontrada</Command.Empty>
            <Command.Group>
              {data?.map((bank) => (
                <Command.Item
                  key={bank.bankCode}
                  value={`${bank.shortBankName} - ${bank.bankCode}`}
                  onSelect={() => handleSelectItem(bank)}
                >
                  {bank.bankCode} - {bank.shortBankName}
                </Command.Item>
              ))}
            </Command.Group>
          </Command.List>
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  );
};
