import {
  formatCNPJ,
  formatCPF,
  isValidCNPJ,
  isValidCPF,
  isValidMobilePhone,
} from "@brazilian-utils/brazilian-utils";

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

export const getAvatarFallback = (name: string) => {
  if (!name) return;
  const firstName = name.split(" ")[0];
  const secondName = name.split(" ")[1] || "";
  return (
    firstName.charAt(0).toLocaleUpperCase() +
    secondName.charAt(0).toLocaleUpperCase()
  );
};

export const removeSpecialCharacters = (value: string) =>
  value.replace(/\D/g, "");

export const formatAccount = (val: string) => {
  const sanitizedInput = removeSpecialCharacters(val);

  if (sanitizedInput.length > 1) {
    return sanitizedInput.slice(0, -1) + "-" + sanitizedInput.slice(-1);
  }
  return sanitizedInput;
};

export const formatPixKey = (pixKey: string) => {
  if (!pixKey) return "";
  const isCpf = isValidCPF(pixKey);

  if (isCpf) return pixKey;

  const isMobilePhone = isValidMobilePhone(pixKey);

  const hasCountryPrefix = pixKey.startsWith("+55");

  let key = "";

  if (isMobilePhone) {
    key = hasCountryPrefix ? pixKey : `+55${pixKey}`;
  } else {
    key = pixKey;
  }

  return key;
};

export const unMask = (value: string) => {
  return value.replace(/[^\d]/g, "");
};

export const removePixSpecialCharacter = (pixKey: string) => {
  if (!pixKey) return "";

  const unmaskedPixKey = unMask(pixKey);

  if (
    isValidCPF(unmaskedPixKey) ||
    isValidCNPJ(unmaskedPixKey) ||
    isValidMobilePhone(unmaskedPixKey)
  )
    return unmaskedPixKey;

  return pixKey;
};

export const truncateDocument = (document: string) => {
  if (isValidCPF(document)) {
    return "***" + formatCPF(document).slice(3, -2) + "**";
  }

  if (isValidCNPJ) {
    return "**" + formatCNPJ(document).slice(2, -2) + "**";
  }

  return document;
};

export const formatDocument = (document: string) => {
  if (isValidCPF) return formatCPF(document);
  if (isValidCNPJ) return formatCNPJ(document);

  return document;
};
