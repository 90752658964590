import { ComponentProps } from "react";

export function EditIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.3 21.9c-.5 0-.925-.175-1.275-.525A1.736 1.736 0 012.5 20.1V6.7c0-.5.175-.925.525-1.275.35-.35.775-.525 1.275-.525h8.55l-1.5 1.5H4.3c-.067 0-.133.033-.2.1s-.1.133-.1.2v13.4c0 .067.033.133.1.2s.133.1.2.1h13.4c.067 0 .133-.033.2-.1s.1-.133.1-.2v-7.075l1.5-1.5V20.1c0 .5-.175.925-.525 1.275-.35.35-.775.525-1.275.525H4.3zM15.525 5.325l1.075 1.05-6.6 6.6V14.4h1.4l6.65-6.625 1.05 1.05-6.575 6.575c-.15.15-.325.27-.525.362-.2.092-.417.138-.65.138H9.4a.87.87 0 01-.638-.262A.87.87 0 018.5 15v-1.95c0-.233.042-.45.125-.65.083-.2.2-.375.35-.525l6.55-6.55zm3.575 3.5l-3.575-3.5 2.3-2.3c.35-.35.78-.525 1.288-.525s.937.183 1.287.55l.975.975c.333.35.5.775.5 1.275 0 .5-.175.925-.525 1.275l-2.25 2.25z"
        fill="#1A1A1A"
      />
    </svg>
  );
}
