import { CNPJ_DIGITS, CPF_DIGITS } from "@/utils";
import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { z } from "zod";

export const transferDataValidationSchema = z.object({
  document: z.custom(
    (document: string) => {
      if (document.length === CPF_DIGITS) return isValidCPF(document);
      if (document.length === CNPJ_DIGITS) return isValidCNPJ(document);
      return false;
    },
    { message: "Documento inválido" },
  ),
  name: z.string().min(1, "Nome é obrigatório"),
  favorite: z.boolean(),
  branch: z.string().min(4, "Agência é obrigatória"),
  account: z.string().min(1, "Conta é obrigatória"),
  bank: z.object({
    bankCode: z.string(),
    bankName: z.string(),
    ispb: z.string(),
    shortBankName: z.string(),
  }),
  accountType: z.union([z.literal("CC"), z.literal("PA"), z.literal("SA")]),
});

export type TransferDataValidationSchema = z.infer<
  typeof transferDataValidationSchema
>;

export const DEFAULT_VALUES: TransferDataValidationSchema = {
  document: "",
  account: "",
  bank: {
    bankCode: "",
    bankName: "",
    ispb: "",
    shortBankName: "",
  },
  branch: "",
  favorite: false,
  name: "",
  accountType: "CC",
};
