import { removeSpecialCharacters } from "@/utils/format";

export const getAccountNumber = (account: string) => {
  const sanitizedValue = removeSpecialCharacters(account);

  const accountNumber = sanitizedValue.substring(0, sanitizedValue.length - 1);

  const accountDigit = sanitizedValue.charAt(sanitizedValue.length - 1);

  return {
    accountNumber,
    accountDigit,
  };
};
