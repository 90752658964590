import { useTransferAccount } from "@/features/transfers/providers";
import { queryClient, TErrorCodes } from "@/lib";
import { PixDetailRequest, PixDetailResponse } from "@/services/pix/types";
import { TransferErrorResponse } from "@/types";
import {
  API,
  formatPixKey,
  getAccountNumber,
  removePixSpecialCharacter,
  routes,
  truncateDocument,
  URL_PARAMS,
  useAccountStore,
} from "@/utils";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

const getPixDetail = async ({
  accountId,
  key,
}: PixDetailRequest): Promise<PixDetailResponse> => {
  try {
    const params = new URLSearchParams();

    params.set("Key", key);
    params.set("AccountId", accountId);

    const response = await API.pix.get<PixDetailResponse>(
      "/api/v1/pix/Key/find",
      { params },
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.data) throw error.response.data;
      throw new Error("Algo deu errado.");
    }

    throw new Error("different error than axios");
  }
};

type QueryOptions = Omit<
  UseQueryOptions<PixDetailResponse, TransferErrorResponse>,
  "queryKey" | "queryFn"
>;

export const pixQueryKey = {
  default: ["getPixDetail"],
  byParam: (pixKey: string) => [...pixQueryKey.default, "byParam", pixKey],
  byFetch: (pixKey: string) => [...pixQueryKey.default, "byComponent", pixKey],
};

export const useGetPixByParam = (options?: QueryOptions) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const pixKeyParam = searchParams.get(URL_PARAMS.pixKey);
  const { updateTransferAccount } = useTransferAccount();

  const sanitizedPixKey = removePixSpecialCharacter(pixKeyParam);

  const pixKey = formatPixKey(sanitizedPixKey);

  const enabled = options?.enabled && !!pixKey;

  const { currentAccountId: accountId } = useAccountStore();

  const result = useQuery<PixDetailResponse, TransferErrorResponse>({
    queryKey: pixQueryKey.byParam(pixKey),
    queryFn: () => getPixDetail({ key: pixKey, accountId }),
    enabled,
    retry: false,
    meta: {
      errCode: TErrorCodes.GET_PIX,
    },
    ...options,
  });

  const { data, isSuccess } = result;

  if (isSuccess && !!pixKey) {
    const { accountDigit, accountNumber } = getAccountNumber(
      data.bankAccountNumber,
    );

    const isFavoriteParam = searchParams.get(URL_PARAMS.isFavorite);

    updateTransferAccount({
      transferType: "pix",
      pixKey: data.key,
      bankName: data.bankName,
      branch: data.bankBranchNumber,
      beneficiaryName: data.name,
      accountDigit,
      accountNumber,
      document: truncateDocument(data.document),
      isFavorite: JSON.parse(isFavoriteParam) || false,
      bankAccountType: data.bankAccountType,
      pixType: "pixKey",
      ISPB: data.ispb,
    });

    queryClient.removeQueries({ queryKey: ["getPixDetail", pixKey] });

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  }

  return result;
};

type GetPixProps = {
  pixKey: string;
} & QueryOptions;

export const useGetPix = ({ pixKey, ...options }: GetPixProps) => {
  const { currentAccountId: accountId } = useAccountStore();

  const sanitizedPixKey = removePixSpecialCharacter(pixKey);

  const key = formatPixKey(sanitizedPixKey);

  return useQuery<PixDetailResponse, TransferErrorResponse>({
    queryKey: pixQueryKey.byFetch(key),
    queryFn: () => getPixDetail({ key, accountId }),
    meta: {
      errCode: TErrorCodes.GET_PIX,
    },
    ...options,
  });
};
