import {
  NavigateOptions,
  To,
  useNavigate as useRouterNavigate,
} from "react-router-dom";

type WebTransitionProps = () => void;

const handleWebTransition = (callback?: WebTransitionProps) => {
  if (!document?.startViewTransition) {
    return callback();
  }

  document.startViewTransition(callback);
};

export const useNavigate = () => {
  const navigate = useRouterNavigate();

  const navigateCallback = (to: To | -1, options?: NavigateOptions) => {
    if (typeof to === "number") {
      handleWebTransition(() => navigate(-1));
    } else {
      return handleWebTransition(() => navigate(to, options));
    }
  };

  return navigateCallback;
};
