import { Contact } from "@/types";
import { BankAccountType } from "@/types/transaction";
import { TODAY_INPUT } from "@/utils";
import { IntlFormatterConfig } from "react-intl-currency-input";
import { tv } from "tailwind-variants";

export type TransferForm = {
  value: number;
  message: string;
  date: string;
};

export const getFormInitialState = (value: number): TransferForm => {
  return {
    value,
    message: "",
    date: TODAY_INPUT,
  };
};

export type Reducer =
  | { name: "value"; value: number }
  | { name: "message"; value: string }
  | { name: "date"; value: string };

export const reducer = (state: TransferForm, action: Reducer): TransferForm => {
  return {
    ...state,
    [action.name]: action.value,
  };
};

export const currencyConfig: IntlFormatterConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const styles = tv({
  slots: {
    dt: "font-base text-neutral-darkest text-x2s/md",
    dd: "font-base text-base text-neutral-dark text-end ml-auto overflow-hidden w-[90%] whitespace-nowrap text-ellipsis",
  },
});

export type FavoredAccountInfo = {
  favoredName: string;
  bankCode: string;
  branch: string;
  account: string;
  bankName: string;
  accountDigit: string;
  branchDigit: string;
  accountType?: BankAccountType;
};

export const getSelectedAccountInfo = (
  favoredData: Contact,
  accountId: string,
): FavoredAccountInfo => {
  if (!accountId || !favoredData) {
    return {
      favoredName: "",
      account: "",
      bankCode: "",
      branch: "",
      bankName: "",
      accountDigit: "",
      branchDigit: "",
      accountType: "CC",
    };
  }

  const selectedAccount = favoredData.ContactAccounts.find(
    (account) => account.Id === accountId,
  );

  if (selectedAccount?.TransferType !== "pix") {
    return {
      favoredName: favoredData.ContactName,
      account: selectedAccount.AccountNumber,
      accountDigit: selectedAccount.AccountDigit,
      bankCode: selectedAccount.BankCode,
      branch: selectedAccount.Branch,
      branchDigit: selectedAccount.BranchDigit,
      bankName: selectedAccount.BankName,
      ...(selectedAccount.TransferType === "ted" && {
        accountType: selectedAccount.BankAccountType,
      }),
    };
  }
};
