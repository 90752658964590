import { MutationConfig, queryClient } from "@/lib";
import { TransferErrorResponse } from "@/types";
import {
  API,
  globalQueryClient,
  queries,
  transfersKeys,
  useAccountStore,
} from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";
import { TransferPixRequest, TransferPixResponse } from "./types";

const { balanceKeys, contactKeys } = queries;

const transferPix = async ({ cafToken, ...payload }: TransferPixRequest) => {
  const response = await API.pix.post<TransferPixResponse>(
    "/api/v1/pix/Transfer",
    payload,
    {
      headers: {
        cafToken,
      },
    },
  );
  return response.data;
};

export const useTransferPix = (
  config?: MutationConfig<typeof transferPix, TransferErrorResponse>,
) => {
  const { currentAccountId: accountId } = useAccountStore();

  return useMutation({
    mutationKey: ["transferMutation"],
    mutationFn: (variables: TransferPixRequest) => transferPix(variables),
    onSuccess: () => {
      globalQueryClient.invalidateQueries({
        queryKey: balanceKeys.getBalance({ accountId }),
      });
      queryClient.invalidateQueries({
        queryKey: transfersKeys.getTransactions(accountId),
      });
      queryClient.invalidateQueries({
        queryKey: contactKeys.getContactList({ accountId }),
      });
    },
    onError: (error) => {
      const errorBody = error?.response?.data?.errors;
      const errorMessage = errorBody?.length
        ? errorBody[0].friendlyMessage
        : "Houve um erro ao realizar a transferência.";

      toast({
        title: "Erro",
        description: errorMessage,
        variant: "error",
      });
    },
    ...config,
  });
};
