import { MutationConfig, queryClient } from "@/lib";
import { TransferErrorResponse } from "@/types";
import {
  API,
  globalQueryClient,
  queries,
  transfersKeys,
  useAccountStore,
  useUserStore,
} from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";
import { CreateTedRequest, CreateTedResponse } from "./types";

const { balanceKeys, contactKeys } = queries;

const createTed = async ({ cafToken, ...payload }: CreateTedRequest) => {
  const { data } = await API.ted.post<CreateTedResponse>(
    "/api/v1/ted/create",
    payload,
    {
      headers: {
        cafToken,
      },
    },
  );
  return data;
};

type UseCreateTedVariables = Omit<
  CreateTedRequest,
  "userId" | "accountId" | "vertical"
>;

export const useCreateTed = (
  config?: MutationConfig<typeof createTed, TransferErrorResponse>,
) => {
  const { currentAccountId: accountId } = useAccountStore();

  const {
    user: { userId, accounts },
  } = useUserStore();

  const vertical =
    accounts.find((account) => account.accountId === accountId).vertical ||
    "Banking";

  return useMutation({
    mutationKey: ["createTed"],
    mutationFn: (variables: UseCreateTedVariables) =>
      createTed({
        userId,
        accountId,
        vertical,
        ...variables,
      }),
    onSuccess: () => {
      globalQueryClient.invalidateQueries({
        queryKey: balanceKeys.getBalance({ accountId }),
      });
      queryClient.invalidateQueries({
        queryKey: transfersKeys.getTransactions(accountId),
      });
      queryClient.invalidateQueries({
        queryKey: contactKeys.getContactList({ accountId }),
      });
    },
    onError: (error) => {
      const errorBody = error?.response?.data?.errors;
      const errorMessage = errorBody?.length
        ? errorBody[0].friendlyMessage
        : "Houve um erro ao realizar a transferência.";

      toast({
        title: "Erro",
        description: errorMessage,
        variant: "error",
        position: "top-right",
      });
    },
    ...config,
  });
};
