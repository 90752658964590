import { useSuspenseGetContacts } from "@/services/contacts";
import { Contact } from "@/types";
import { routes } from "@/utils";
import { URL_PARAMS } from "@/utils/constants";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "./handle-web-transition";

const getFavoredData = ({
  contactList,
  beneficiary,
}: {
  contactList: Contact[];
  beneficiary: string;
}) => {
  return contactList.find((contact) => contact.Id === beneficiary);
};

export const useGetFavoredData = () => {
  const { data } = useSuspenseGetContacts();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const beneficiary = searchParams.get(URL_PARAMS.beneficiary);
  const transferType = searchParams.get(URL_PARAMS.type) || "ted";

  const favoredData = getFavoredData({
    contactList: data[transferType],
    beneficiary,
  });

  // if (!favoredData && transferType !== "pix") {
  //   navigate(routes.transfers);
  //   return null;
  // }

  return favoredData;
};
