import { OutletContext } from "@/types";
import { routes } from "@/utils";
import { Sheet } from "@hyperlocal/vital2";
import {
  Outlet as ReactRouterOutlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

export const Outlet = ({
  handleTransfer,
  isLoading,
  transferType,
  transferData,
}: OutletContext) => {
  const location = useLocation();

  const navigate = useNavigate();

  const isSheetOpen = [routes.transferReceipt, routes.transferToken].includes(
    location.pathname,
  );

  const closeNavigation = () => {
    if (location.pathname === routes.transferToken)
      return navigate(
        { pathname: routes.transfersConfirmTransfer, search: location.search },
        { replace: true },
      );

    return navigate({ pathname: routes.transfers }, { replace: true });
  };

  const handleClose = (open: boolean) => {
    if (!open) closeNavigation();
  };

  return (
    <Sheet.Root onOpenChange={handleClose} open={isSheetOpen}>
      <Sheet.Content
        className="!p-0"
        onEscapeKeyDown={(e) => {
          if (isLoading) e.preventDefault();
        }}
        onPointerDownOutside={(e) => {
          if (isLoading) e.preventDefault();
        }}
      >
        {location.pathname === routes.transferToken && (
          <Sheet.Header className="pl-6 pt-6">
            <Sheet.Title>Validação</Sheet.Title>
          </Sheet.Header>
        )}
        <ReactRouterOutlet
          context={{
            handleTransfer,
            isLoading,
            transferType,
            transferData,
          }}
        />
      </Sheet.Content>
    </Sheet.Root>
  );
};
