import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";
import { useNavigate } from "@/hooks";
import Icon from "@hyperlocal/vital-icons";
import { NavigateOptions, To } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const LayoutRoot = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge(
      "flex h-full w-full flex-1 flex-col overflow-hidden py-[24px] mobile:!pt-0",
      className,
    )}
    {...rest}
  />
));
LayoutRoot.displayName = "TransferLayoutRoot";

interface LayoutTitleProps extends ComponentPropsWithoutRef<"h5"> {
  to?: To;
  options?: NavigateOptions;
}

const LayoutTitle = forwardRef<ElementRef<"h5">, LayoutTitleProps>(
  ({ className, children, to, options, ...rest }, ref) => {
    const navigate = useNavigate();

    const handleBack = () => {
      navigate(to, options);
    };

    return (
      <h5
        ref={ref}
        className={twMerge(
          "mb-4 flex items-center gap-2 px-6 font-base text-sm/8 font-bold text-neutral-darkest mobile:!text-base",
          className,
        )}
        {...rest}
      >
        {to && (
          <button onClick={handleBack}>
            <Icon
              name="ArrowNoLineLeft"
              fill="currentColor"
              className="size-4"
            />
          </button>
        )}
        {children}
      </h5>
    );
  },
);
LayoutTitle.displayName = "TransferLayoutTitle";

const LayoutFooter = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...rest }, ref) => (
  <div
    ref={ref}
    className={twMerge("mt-6 flex w-full gap-2 px-6", className)}
    {...rest}
  />
));
LayoutFooter.displayName = "TransferLayoutFooter";

export const TransferLayout = {
  Root: LayoutRoot,
  Title: LayoutTitle,
  Footer: LayoutFooter,
};
