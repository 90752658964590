import { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  ${({ theme }) => css`
    body {
      font-family: ${theme.fonts.base};
      background-color: ${theme.palette.neutral.lighter};
      color: ${theme.palette.neutral.dark};
    }
  `};
`;
