import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Accordion as Root,
} from "./accordion";

export const Accordion = {
  Root,
  Content: AccordionContent,
  Item: AccordionItem,
  Trigger: AccordionTrigger,
};
