import { MutationConfig, queryClient } from "@/lib";
import { Contact } from "@/types";
import { API, queries, useAccountStore } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { ContactErrorResponse, UpdateContactRequest } from "./types";

const { contactKeys } = queries;

const updateAccount = async (contact: UpdateContactRequest) => {
  try {
    const response = await API.contact.put<Contact>("/Contact", contact);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};

type UseUpdateMutationVariables = Omit<UpdateContactRequest, "accountId">;

export const useUpdateAccount = (
  config?: MutationConfig<typeof updateAccount, ContactErrorResponse>,
) => {
  const { currentAccountId: accountId } = useAccountStore();

  return useMutation({
    mutationKey: ["updateAccount"],
    mutationFn: (variables: UseUpdateMutationVariables) =>
      updateAccount({
        accountId,
        ...variables,
      }),
    onError: () => {
      toast({
        title: "Erro",
        description: "Houve um erro ao atualizar os dados do contato",
        variant: "error",
      });
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: contactKeys.getContactList({
          accountId,
          document: variables?.document,
        }),
      });
    },
    ...config,
  });
};
