import { format } from "date-fns";

const formatDateToISO = (date: Date) => {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const formatDateToBR = (date: Date) => {
  return date.toLocaleDateString();
};

export { formatDateToISO, formatDateToBR };

export const getTime = (date: Date) => {
  return new Date(date).toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const isValidDate = (date: Date) => {
  return date instanceof Date && Number.isFinite(date.getTime());
};

export const handleNormalizeDate = (date: Date | string) => {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
};

export const formatToInputDate = (date: Date) => format(date, "yyyy-MM-dd");

// 01/04/2024 -> 01 de abril
export const getDateTitle = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "long",
  });
};

export const isSameDate = (dateA: Date, dateB: Date) => {
  dateA.setHours(0, 0, 0, 0);
  dateB.setHours(0, 0, 0, 0);

  return dateA.getTime() === dateB.getTime();
};

export const getGroupedDate = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};
