import EmptyLogo from "@/assets/lupinha.png";
import { ScrollArea } from "@/components";
import { useTransferAccount } from "@/features/transfers/providers";
import { useNavigate } from "@/hooks";
import { useGetBanks } from "@/services/banks";
import { useSuspenseGetContacts } from "@/services/contacts";
import { useGetPixByParam } from "@/services/pix";
import { Contact, ContactAccount, TransferType } from "@/types";
import {
  formatDocument,
  getAccountNumber,
  getAvatarFallback,
  routes,
  URL_PARAMS,
} from "@/utils";
import Icon from "@hyperlocal/vital-icons";
import { Avatar, Card } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

type RedirectPixProps = {
  contact: Contact;
  account: ContactAccount;
};

const filterList = ({
  list,
  search,
}: {
  list: Contact[];
  search: string;
  type: TransferType;
}) => {
  const searchedValue = search.toLocaleLowerCase();

  const filteredList = list.filter(({ ContactName, Document }) => {
    return (
      ContactName.toLocaleLowerCase().includes(searchedValue) ||
      Document.includes(searchedValue)
    );
  });

  return filteredList;
};

export const ContactList = () => {
  const { data } = useSuspenseGetContacts();
  const { data: bankList } = useGetBanks();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get(URL_PARAMS.search) || "";
  const transferType = (searchParams.get(URL_PARAMS.type) ||
    "ted") as TransferType;
  const navigate = useNavigate();
  const { updateTransferAccount } = useTransferAccount();

  const list = filterList({
    list: data[transferType],
    search,
    type: transferType,
  });

  const { isLoading } = useGetPixByParam({
    enabled: false,
  });

  if (!list.length) {
    return (
      <div className="flex h-full flex-col items-center">
        <img src={EmptyLogo} alt="Empty" className="size-[146px]" />
        <span className="max-w-[340px] text-center text-base text-neutral-main">
          Nenhum contato encontrado. Por favor, inicie uma nova transferência.
        </span>
      </div>
    );
  }

  const isPixFetching = isLoading;

  const handleRedirectPixByKey = ({ account, contact }: RedirectPixProps) => {
    if (account.TransferType !== "pix") return;

    searchParams.set(URL_PARAMS.pixKey, account.PixKey);
    searchParams.set(URL_PARAMS.beneficiary, contact.Id);
    searchParams.set(URL_PARAMS.accountId, account.Id);
    searchParams.set(
      URL_PARAMS.isFavorite,
      JSON.stringify(contact.IsFavoredContact),
    );

    setSearchParams(searchParams);
  };

  const handleRedirectPix = ({ account, contact }: RedirectPixProps) => {
    if (account.TransferType !== "pix") return;

    if (account.PixKey) return handleRedirectPixByKey({ account, contact });

    const { accountDigit, accountNumber } = getAccountNumber(
      account.AccountNumber,
    );

    const accountBankData = bankList.find((bank) => {
      return bank.ispb === account.ISPB.padStart(8, "0");
    });

    updateTransferAccount({
      transferType: "pix",
      isFavorite: contact.IsFavoredContact,
      accountDigit,
      accountNumber,
      bankAccountType: account.BankAccountType || "CC",
      bankName: accountBankData.shortBankName,
      ISPB: Number(accountBankData.ispb),
      beneficiaryName: contact.ContactName,
      branch: account.Branch,
      pixKey: "",
      document: formatDocument(contact.Document),
      pixType: "account",
    });

    searchParams.set(URL_PARAMS.beneficiary, contact.Id);
    searchParams.set(URL_PARAMS.accountId, account.Id);
    searchParams.set(
      URL_PARAMS.isFavorite,
      JSON.stringify(contact.IsFavoredContact),
    );

    setSearchParams(searchParams);

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const handleRedirect = async (contact: Contact) => {
    if (isPixFetching) return;

    const firstAccount = contact.ContactAccounts.find(
      (account) => account.TransferType === transferType,
    );

    if (firstAccount.TransferType === "pix") {
      handleRedirectPix({ account: firstAccount, contact });
      return;
    } else {
      searchParams.set(URL_PARAMS.beneficiary, contact.Id);
      searchParams.set(URL_PARAMS.type, firstAccount.TransferType);
      searchParams.set(URL_PARAMS.accountId, firstAccount.Id);
    }

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  return (
    <ScrollArea className="h-full w-full px-6">
      <ul className="flex flex-col gap-2">
        {list.map((contact) => (
          <li key={contact.Id} className="flex">
            <button
              className={twMerge(
                "flex flex-1",
                isPixFetching && "cursor-not-allowed",
              )}
              onClick={() => handleRedirect(contact)}
              disabled={isPixFetching}
              title={contact.ContactName}
            >
              <Card className="flex min-w-0 flex-1 items-center gap-2">
                <Avatar.Root
                  variant={contact.IsFavoredContact ? "primary" : "secondary"}
                  size="md"
                  className="shrink-0 shadow-[0px_0px_0px_1px]"
                >
                  <Avatar.Fallback>
                    {getAvatarFallback(contact.ContactName)}
                  </Avatar.Fallback>
                </Avatar.Root>
                <span className="overflow-hidden text-ellipsis whitespace-nowrap font-base text-base text-neutral-darkest">
                  {contact.ContactName}
                </span>
                <Icon
                  name="ArrowArrowNoLineRight"
                  className="ml-auto size-6 flex-shrink-0 fill-neutral-darkest"
                />
              </Card>
            </button>
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
};
