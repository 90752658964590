import { Fragment } from "react";
import { ScrollArea } from "@/components";
import { OutletContext, TransferType } from "@/types";
import { formatCurrency, getVerticalBankName, useAccountStore } from "@/utils";
import { IconProps } from "@hyperlocal/vital-icons";
import { Avatar } from "@hyperlocal/vital2";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";

export const descriptionStyles = tv({
  slots: {
    definitionTerm:
      "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    definitionDetail:
      "font-base text-neutral-dark text-xs/default text-end overflow-hidden text-ellipsis",
    definitionContainer: "grid grid-cols-[auto_1fr] my-4 gap-4 w-full",
  },
});

type PendingReceiptData = OutletContext["transferData"];

const getReceiptData = (transferData: PendingReceiptData) => {
  const defaultData = {
    Valor: formatCurrency(transferData.value),
  };

  if (transferData.transferType === "ted")
    return {
      ...defaultData,
      Favorecido: transferData.beneficiaryName,
      Instituição: transferData.bankName,
      Agência: transferData.branch,
      Conta: `${transferData.accountNumber}-${transferData.accountDigit}`,
    };

  if (transferData.transferType === "pix")
    return {
      ...defaultData,
      "Chave Pix": transferData.pixKey,
    };

  if (transferData.transferType === "p2p")
    return {
      ...defaultData,
      Favorecido: transferData.beneficiaryName,
    };
};

export const Receipt = () => {
  const { transferData } = useOutletContext<OutletContext>();
  const { account } = useAccountStore();

  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const [searchParams] = useSearchParams();

  const receiptId = searchParams.get("receiptId");
  const receiptType = searchParams.get("receiptType");
  const movement = searchParams.get("movement");

  if (receiptId)
    return (
      <Parcel
        config={() => System.import("@hyperlocal/banking-receipt")}
        mountParcel={mountRootParcel}
        receiptId={receiptId}
        receiptType={receiptType}
        movement={movement}
      />
    );

  if (!transferData) return null;

  const LabelMap: Record<
    TransferType,
    { label: string; icon: IconProps["name"] }
  > = {
    ted: {
      label: "TED",
      icon: "GeralMoneyPayReceived",
    },
    pix: {
      label: "Pix",
      icon: "MoneyPix",
    },
    p2p: {
      label: getVerticalBankName(),
      icon: "GeralMoneyPayReceived",
    },
  };

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6">
      <h4 className="font-inter -mt-2 ml-4 pb-4 text-2xl font-bold text-neutral-darkest mobile:!text-sm">
        Detalhes da transferência
      </h4>
      <div className="mb-6 flex w-full flex-col items-center overflow-hidden">
        <ScrollArea className="flex h-full w-full flex-col px-6">
          <div className="flex w-full flex-col items-center overflow-hidden">
            <Avatar.Root
              variant="primary"
              size="lg"
              className="mb-4 mt-8 bg-status-warning-light text-status-warning-light"
            >
              <Avatar.Slot
                name={LabelMap[transferData.transferType].icon}
                fill="currentColor"
                className="!text-status-warning-dark"
              />
            </Avatar.Root>
            <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
              Transferência {LabelMap[transferData.transferType].label}
            </h5>
            <h5 className="mb-6 font-base text-sm/md font-bold text-status-warning-dark">
              Pendente
            </h5>
            <div className="flex h-full w-full flex-col overflow-hidden">
              {transferData.message && (
                <>
                  <hr className="h-[1px] w-full bg-neutral-light" />
                  <dl className="my-4 h-full w-full">
                    <dt className={definitionTerm()}>Mensagem</dt>
                    <dd
                      className={twMerge(definitionDetail(), "mt-1 text-start")}
                    >
                      {transferData.message}
                    </dd>
                  </dl>
                </>
              )}
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Data da transferência</dt>
                <dd className={definitionDetail()}>Aguardando aprovação</dd>
                {Object.entries(getReceiptData(transferData)).map(
                  ([key, value]) => (
                    <Fragment key={key}>
                      <dt className={definitionTerm()}>{key}</dt>
                      <dd className={definitionDetail()}>{value}</dd>
                    </Fragment>
                  ),
                )}
              </dl>
            </div>
            <hr className="h-[1px] w-full bg-neutral-light" />
            <dl className={definitionContainer()}>
              <dt className={definitionTerm()}>Pagador</dt>
              <dd className={definitionDetail()}>
                {account.personInfoResponse?.name ||
                  account.companyInfoResponse.fantasyName}
              </dd>
              {transferData.transferType !== "p2p" && (
                <>
                  <dt className={definitionTerm()}>Instituição</dt>
                  <dd className={definitionDetail()}>HyperLocal S.A</dd>
                  <dt className={definitionTerm()}>Agência</dt>
                  <dd className={definitionDetail()}>{account.branch}</dd>
                  <dt className={definitionTerm()}>Conta</dt>
                  <dd
                    className={definitionDetail()}
                  >{`${account.accountNumber}-${account.accountDigit}`}</dd>
                </>
              )}
            </dl>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};
