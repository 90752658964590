import { useState } from "react";
import { useNavigate } from "@/hooks";
import { useGetPixByParam } from "@/services/pix";
import { TransferType } from "@/types";
import { getVerticalBankName, routes, URL_PARAMS } from "@/utils";
import { Button, RadioGroup, TextField } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { ContactList, InsertPixKey, TransferLayout } from "../../components";

export const SelectTransferType = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = (searchParams.get(URL_PARAMS.type) || "ted") as TransferType;

  const [transferType, setTransferType] = useState<TransferType>(type);
  const [searchValue, setSearchValue] = useState("");
  const [isPixKeyOpen, setIsPixKeyOpen] = useState(false);

  const pixKey = searchParams.get(URL_PARAMS.pixKey);

  const { isLoading } = useGetPixByParam({
    enabled: !!pixKey,
  });

  const handleContinue = () => {
    if (transferType === "pix") return setIsPixKeyOpen(true);

    searchParams.set(URL_PARAMS.type, transferType);

    navigate({
      pathname: routes.transfersData,
      search: searchParams.toString(),
    });
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target.value) {
      setSearchValue("");
      searchParams.delete(URL_PARAMS.search);
    } else {
      setSearchValue(target.value);
      searchParams.set(URL_PARAMS.search, target.value);
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <TransferLayout.Root>
        <div className="px-6 mobile:!px-4">
          <TransferLayout.Title className="px-0">
            Tipo da transferência
          </TransferLayout.Title>
          <RadioGroup.Root
            defaultValue={transferType}
            className="mb-3 mt-4 grid auto-cols-auto grid-cols-3 grid-rows-1"
            onValueChange={(value: TransferType) => {
              setTransferType(value);
              searchParams.set(URL_PARAMS.type, value);
              setSearchParams(searchParams);
            }}
          >
            <RadioGroup.Items>
              <RadioGroup.Item
                value="ted"
                id="ted"
                className="radio-button-transfers-wildcard"
              />
              <RadioGroup.Label htmlFor="ted">TED</RadioGroup.Label>
            </RadioGroup.Items>
            <RadioGroup.Items>
              <RadioGroup.Item
                value="p2p"
                id="p2p"
                className="radio-button-transfers-wildcard shrink-0"
              />
              <RadioGroup.Label htmlFor="p2p">
                {getVerticalBankName()}
              </RadioGroup.Label>
            </RadioGroup.Items>
            <RadioGroup.Items className="mobile:!justify-self-end">
              <RadioGroup.Item
                value="pix"
                id="pix"
                className="radio-button-transfers-wildcard"
              />
              <RadioGroup.Label htmlFor="pix">Pix</RadioGroup.Label>
            </RadioGroup.Items>
          </RadioGroup.Root>
          <TextField.Label htmlFor="transfer-input">
            Busque pelo contato
          </TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="transfer-input"
              value={searchValue}
              onChange={handleInputChange}
            />
          </TextField.Root>
          <h5 className="transfers-all-contacts-wildcard my-4 font-base text-sm/8 font-bold text-neutral-darkest mobile:!text-base">
            Todos os contatos
          </h5>
        </div>
        <ContactList />
        <TransferLayout.Footer>
          <Button.Root fullWidth onClick={handleContinue} isLoading={isLoading}>
            Continuar
          </Button.Root>
        </TransferLayout.Footer>
      </TransferLayout.Root>
      <InsertPixKey isOpen={isPixKeyOpen} onOpenChange={setIsPixKeyOpen} />
    </>
  );
};
