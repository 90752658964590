import { TransferErrorResponse } from "@/types";
import { globalQueryClient, URL_PARAMS } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import {
  Query,
  QueryCache,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

export const TErrorCodes = {
  GET_PIX: "GET_PIX",
};

type PixError = Error & TransferErrorResponse;

const isTransferErrorResponse = (error: PixError) => {
  return !!error?.errors?.length;
};

const handlePixError = (error: PixError) => {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  const errorMessage = isTransferErrorResponse(error)
    ? error?.errors[0].friendlyMessage
    : "Não foi possível buscar os dados.";

  toast({
    title: "Erro",
    description: errorMessage,
    variant: "error",
    position: "top-right",
  });
  searchParams.delete(URL_PARAMS.pixKey);

  window.history.replaceState({}, "", url.toString());
};

function queryCacheOnError(err: PixError, query: Query) {
  switch (query.meta?.errCode) {
    case TErrorCodes.GET_PIX:
      return handlePixError(err);
    default:
      return console.log("Something went wrong");
  }
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: queryCacheOnError,
  }),
});

export type RequestError<TError = unknown> = AxiosError<TError>;

export type QueryConfig<FetcherFnType extends (...args: unknown[]) => unknown> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

export type MutationConfig<
  FetcherFnType extends (...args: unknown[]) => unknown,
  TError = unknown,
> = UseMutationOptions<
  Awaited<ReturnType<FetcherFnType>>,
  RequestError<TError>,
  Parameters<FetcherFnType>[0]
>;
