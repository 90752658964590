import { Spinner } from "@/components";

export const LoadingFallback = () => {
  return (
    <div
      role="progressbar"
      aria-valuetext="Carregando..."
      aria-busy="true"
      aria-live="assertive"
      aria-valuemin={0}
      aria-valuemax={100}
      className="flex h-full flex-1 flex-col items-center justify-center gap-stack-sm"
    >
      <h2 className="text-4xl font-medium">Carregando...</h2>
      <Spinner />
    </div>
  );
};
