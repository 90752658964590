import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Bank, BankResponse } from "./types";

const getBanks = async () => {
  try {
    const response = axios.get<BankResponse[]>(
      "https://brasilapi.com.br/api/banks/v1",
      {
        headers: {
          "content-type": "application/json;",
        },
      },
    );

    return (await response).data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};

const transformBankResponse = (response: BankResponse[]): Bank[] => {
  const result = response.reduce((bankList, bank) => {
    if (bank?.code) {
      const code = bank.code?.toString();

      const bankCode = code.length <= 2 ? code.padStart(3, "0") : code;

      bankList.push({
        bankCode,
        ispb: bank.ispb,
        bankName: bank.fullName,
        shortBankName: bank.name,
      });
    }
    return bankList;
  }, []);

  return result;
};

export const useGetBanks = () => {
  return useQuery({
    queryKey: ["getBanks"],
    queryFn: getBanks,
    select: (data) => transformBankResponse(data),
  });
};
