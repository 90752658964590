import { useEffect } from "react";
import { routes } from "@/utils";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "./handle-web-transition";

export const useHandleSearchDependencies = (dependencies: string[]) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    dependencies.forEach((dependency) => {
      if (!searchParams.get(dependency)) {
        return navigate(routes.transfers);
      }
    });
  }, [dependencies, navigate, searchParams]);
};
