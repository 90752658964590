import { ComponentProps, forwardRef } from "react";
import { Skeleton } from "@/components";
import { Card } from "@hyperlocal/vital2";

export const LoadingTransaction = forwardRef<
  HTMLDivElement,
  ComponentProps<"div">
>((props, ref) => {
  return (
    <div className="flex flex-col gap-4" ref={ref} {...props}>
      <Card className="flex w-full items-center gap-4 px-6 py-3 hover:border-neutral-lighter">
        <Skeleton className="size-10 shrink-0 rounded-full" />
        <div className="flex w-full flex-col gap-2">
          <Skeleton className="h-4 w-full max-w-[50%]" />
          <Skeleton className="h-4 w-full max-w-[25%]" />
        </div>
      </Card>
    </div>
  );
});
LoadingTransaction.displayName = "LoadingTransaction";
