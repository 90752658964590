import { useEffect, useReducer, useState } from "react";
import { ScrollArea } from "@/components";
import { useGetFavoredData, useNavigate } from "@/hooks";
import { queryClient } from "@/lib";
import { useUpdateAccount } from "@/services/contacts";
import { UpdateContactAccount } from "@/services/contacts/types";
import { useGetPixByParam } from "@/services/pix";
import { ContactAccount } from "@/types";
import {
  canEditContacts,
  getAccountNumber,
  ProtectedComponent,
  queries,
  routes,
  URL_PARAMS,
  useAccountStore,
} from "@/utils";
import {
  formatCNPJ,
  formatCPF,
  isValidCPF,
} from "@brazilian-utils/brazilian-utils";
import { Button, Switch, TextField } from "@hyperlocal/vital2";
import { useSearchParams } from "react-router-dom";
import { TransferLayout } from "../../components";
import { AccountList } from "./account-list";
import {
  BankForm,
  FORM_INITIAL_STATE,
  handleUnfinishedBankForm,
  reducer,
} from "./helper";

const { contactKeys } = queries;

const findAccount = ({
  accountList,
  bankForm,
}: {
  accountList: ContactAccount[];
  bankForm: BankForm;
}) => {
  const account = accountList.find((account) => {
    if (account.TransferType === "pix") return null;

    const { accountDigit, accountNumber } = getAccountNumber(bankForm.account);

    const isSameBranch = account.Branch === bankForm.branch;

    const isSameAccountNumber = account.AccountNumber === accountNumber;

    const isSameAccountDigit = account.AccountDigit === accountDigit;

    const bankCode = account.BankCode === bankForm.bank.bankCode;

    if (isSameBranch && isSameAccountNumber && isSameAccountDigit && bankCode)
      return account;

    return null;
  });

  return account;
};

export const EditContact = () => {
  const [searchParams] = useSearchParams();
  const favoredData = useGetFavoredData();
  const [bankForm, updateBankForm] = useReducer(reducer, FORM_INITIAL_STATE);
  const [isFavorite, setIsFavorite] = useState(favoredData?.IsFavoredContact);
  const navigate = useNavigate();

  const pixKey = searchParams.get(URL_PARAMS.pixKey);

  const { currentAccountId } = useAccountStore();

  const transferType = searchParams.get(URL_PARAMS.type);

  const beneficiary = searchParams.get(URL_PARAMS.beneficiary);

  const accountId = searchParams.get(URL_PARAMS.accountId);

  const unfinishedForm =
    bankForm.addAccount &&
    (!bankForm.account || !bankForm.bank || !bankForm.branch);

  const updateMutation = useUpdateAccount();

  const { isLoading } = useGetPixByParam({
    enabled: !!pixKey,
  });

  const onUpdate = async () => {
    if (unfinishedForm) return handleUnfinishedBankForm();

    const { accountDigit, accountNumber } = getAccountNumber(bankForm.account);

    const contactAccounts: UpdateContactAccount[] = [
      ...favoredData.ContactAccounts,
    ];

    if (
      bankForm.addAccount &&
      !findAccount({ bankForm, accountList: favoredData.ContactAccounts })
    )
      contactAccounts.push({
        TransferType: "ted",
        AccountDigit: accountDigit,
        AccountNumber: accountNumber,
        BankAccountType: bankForm.accountType,
        BankCode: bankForm.bank.bankCode,
        BankName: bankForm.bank.bankName,
        Branch: bankForm.branch,
        BranchDigit: "0",
      });

    const response = await updateMutation.mutateAsync({
      contactName: favoredData.ContactName,
      document: favoredData.Document,
      accounts: contactAccounts,
      isFavoredContact: isFavorite,
    });

    await queryClient.invalidateQueries({
      queryKey: contactKeys.getContactList({
        accountId: currentAccountId,
      }),
    });

    const account = findAccount({
      bankForm,
      accountList: response.ContactAccounts,
    });

    searchParams.set(URL_PARAMS.beneficiary, response.Id);
    searchParams.set(URL_PARAMS.type, transferType);
    if (account) searchParams.set(URL_PARAMS.accountId, account.Id);

    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const handleCancel = () => {
    navigate({
      pathname: routes.transfersConfirmTransfer,
      search: searchParams.toString(),
    });
  };

  const documentValue = isValidCPF(favoredData?.Document)
    ? formatCPF(favoredData?.Document)
    : formatCNPJ(favoredData?.Document);

  const shouldBeAbleToChooseAccount = ["ted", "pix"].includes(transferType);

  const shouldBeAbleToSwitchFavorite = !favoredData?.IsFavoredContact;

  useEffect(() => {
    const shouldRedirect = !transferType || !beneficiary;

    if (shouldRedirect) navigate(routes.transfers);
  }, [accountId, beneficiary, navigate, transferType]);

  return (
    <TransferLayout.Root>
      <TransferLayout.Title
        to={{
          pathname: routes.transfersConfirmTransfer,
          search: searchParams.toString(),
        }}
        className="mobile:!hidden"
      >
        Editar contato
      </TransferLayout.Title>
      <ScrollArea className="h-full w-full px-5">
        <div className="px-1">
          <div>
            <TextField.Label htmlFor="taxId">CPF/CNPJ</TextField.Label>
            <TextField.Root>
              <TextField.Input value={documentValue} disabled id="taxId" />
            </TextField.Root>
          </div>
          <div className="my-6">
            <TextField.Label htmlFor="name">Nome completo</TextField.Label>
            <TextField.Root>
              <TextField.Input
                value={favoredData?.ContactName}
                disabled
                id="name"
              />
            </TextField.Root>
          </div>
          {shouldBeAbleToSwitchFavorite && (
            <ProtectedComponent allowedRoles={canEditContacts}>
              <div className="flex w-fit cursor-pointer gap-2">
                <Switch
                  id="favoriteContact"
                  checked={isFavorite}
                  onCheckedChange={(checked) => setIsFavorite(checked)}
                />
                <label htmlFor="favoriteContact" className="cursor-pointer">
                  Favoritar contato
                </label>
              </div>
            </ProtectedComponent>
          )}
          {shouldBeAbleToChooseAccount && (
            <>
              <h5 className="my-4 font-base text-sm/8 font-bold text-neutral-darkest mobile:!text-base">
                Dados da conta
              </h5>
              <AccountList
                updateBankForm={updateBankForm}
                bankForm={bankForm}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
      </ScrollArea>
      <TransferLayout.Footer>
        <Button.Root
          disabled={updateMutation.isPending || isLoading}
          fullWidth
          variant="secondary"
          onClick={handleCancel}
        >
          Cancelar
        </Button.Root>
        <ProtectedComponent allowedRoles={canEditContacts}>
          <Button.Root
            fullWidth
            onClick={onUpdate}
            disabled={isLoading}
            isLoading={updateMutation.isPending}
          >
            Salvar alteração
          </Button.Root>
        </ProtectedComponent>
      </TransferLayout.Footer>
    </TransferLayout.Root>
  );
};
