import { routes } from "@/utils";
import {
  ProtectedRoute,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Route, Routes as Switch } from "react-router-dom";
import {
  ConfirmTransfer,
  EditContact,
  MFAValidation,
  Receipt,
  SelectTransferType,
  TransferData,
  TransferRoot,
} from "../pages";

export const TransferRoutes = () => {
  return (
    <Switch>
      <Route
        path={routes.transfers}
        element={
          <ProtectedRoute
            allowedRoles={[
              "Administrador",
              "Proprietário",
              "Moderador",
              "Financeiro",
            ]}
          >
            <TransferRoot />
          </ProtectedRoute>
        }
      >
        <Route path={routes.transfers} element={<SelectTransferType />} />
        <Route path={routes.transfersData} element={<TransferData />} />
        <Route
          path={routes.transfersConfirmTransfer}
          element={<ConfirmTransfer />}
        >
          <Route path={routes.transferToken} element={<MFAValidation />} />
          <Route path={routes.transferReceipt} element={<Receipt />} />
        </Route>
        <Route path={routes.transfersEditContact} element={<EditContact />} />
      </Route>
    </Switch>
  );
};
