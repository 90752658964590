import { Contact, TransferErrorResponse, TransferType } from "@/types";
import { API, queries, useAccountStore } from "@/utils";
import {
  useQuery,
  UseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { GetContactListRequest, GetContactListResponse } from "./types";

const { contactKeys } = queries;

const getContacts = async ({
  accountId,
  document,
  favorite,
}: GetContactListRequest): Promise<GetContactListResponse> => {
  try {
    const params = new URLSearchParams();
    params.append("accountId", accountId);
    if (document) params.append("document", document);
    if (favorite) params.append("favoredContacts", "true");

    const response = await API.contact.get<GetContactListResponse>("/Contact", {
      params,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) throw error;

    throw new Error("different error than axios");
  }
};

interface UseGetContactsResponse {
  pix: Contact[];
  ted: Contact[];
  p2p: Contact[];
}

const sortFavoriteList = (list: Contact[]) => {
  const favoriteContacts = list.filter((contact) => !!contact.IsFavoredContact);
  const notFavoriteContacts = list.filter(
    (contact) => !contact.IsFavoredContact,
  );

  return [...favoriteContacts, ...notFavoriteContacts];
};

const getContactsByType = ({
  response,
  type,
}: {
  response: GetContactListResponse;
  type: TransferType;
}) => {
  return response.Contacts.reduce((prev, cur) => {
    const hasPixContact = cur.ContactAccounts.some(
      (contact) => contact.TransferType === type,
    );

    if (hasPixContact) prev.push(cur);

    return prev;
  }, []);
};

const transformContactResponse = (
  response: GetContactListResponse,
): UseGetContactsResponse => {
  const pixContacts = getContactsByType({ response, type: "pix" });
  const tedContacts = getContactsByType({ response, type: "ted" });
  const p2pContacts = getContactsByType({ response, type: "p2p" });

  return {
    pix: sortFavoriteList(pixContacts),
    ted: sortFavoriteList(tedContacts),
    p2p: sortFavoriteList(p2pContacts),
  };
};

interface UseGetContacts extends Omit<GetContactListRequest, "accountId"> {
  config?: UseQueryOptions<
    GetContactListResponse,
    TransferErrorResponse,
    UseGetContactsResponse
  >;
}

export const useGetContacts = (props?: UseGetContacts) => {
    const { currentAccountId: accountId } = useAccountStore();

  return useQuery({
    queryKey: contactKeys.getContactList({
      accountId,
      document: props?.document,
    }),
    queryFn: () => getContacts({ accountId, document: props?.document }),
    select: (data) => transformContactResponse(data),
    ...props?.config,
  });
};

export const useSuspenseGetContacts = (props?: UseGetContacts) => {
    const { currentAccountId: accountId } = useAccountStore();
  return useSuspenseQuery({
    queryKey: contactKeys.getContactList({
      accountId,
      document: props?.document,
    }),
    queryFn: () => getContacts({ accountId, document: props?.document }),
    select: (data) => transformContactResponse(data),
    ...props?.config,
  });
};
