import { Bank } from "@/services/banks/types";
import { BankAccountType } from "@/types/transaction";
import { URL_PARAMS } from "@/utils";
import { toast } from "@hyperlocal/vital2";

export type BankForm = {
  branch: string;
  account: string;
  bank: Bank;
  addAccount: boolean;
  accountType: BankAccountType;
};

export const FORM_INITIAL_STATE: BankForm = {
  account: "",
  bank: {
    bankCode: "",
    bankName: "",
    ispb: "",
    shortBankName: "",
  },
  branch: "",
  addAccount: false,
  accountType: "CC",
};

export type Reducer =
  | { name: "addAccount"; value: boolean }
  | { name: "accountType"; value: BankAccountType }
  | { name: "bank"; value: Bank }
  | {
      name: Exclude<keyof BankForm, "addAccount" | "accountType" | "bank">;
      value: string;
    };

export const reducer = (state: BankForm, action: Reducer): BankForm => {
  return {
    ...state,
    [action.name]: action.value,
  };
};

export const handleUnfinishedBankForm = () => {
  toast({
    title: "Erro",
    description: "Preencha todos os campos para adicionar a conta!",
    variant: "inform",
  });
};

export const editAccountSearchDependencies = () => {
  const dependencies = [
    URL_PARAMS.beneficiary,
    URL_PARAMS.type,
    URL_PARAMS.accountId,
  ];

  return dependencies;
};
