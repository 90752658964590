import { useEffect, useState } from "react";

export const breakpoints = {
  mobile: "(max-width: 1080px)",
  desktop: "(min-width: 1081px)",
};

type Breakpoints = keyof typeof breakpoints;

export const useMediaQuery = (breakpoint: Breakpoints): boolean => {
  const query = breakpoints[breakpoint];

  const getMatches = (matches: string): boolean => {
    // Prevents SSR issues
    return typeof window !== "undefined"
      ? window.matchMedia(matches)?.matches
      : false;
  };

  const [matches, setMatches] = useState<boolean>(() => getMatches(query));

  const handleChange = () => {
    setMatches(getMatches(query));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};
