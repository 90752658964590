import { getVertical, Vertical } from "@/utils";

export const getVerticalBankName = () => {
  const vertical = getVertical();

  const bankName: Record<Vertical, string> = {
    avec: "Avec",
    hyperlocal: "Hyperlocal",
    crossX: "Hyperlocal",
    go2go: "Hyperlocal",
    timo: "Hyperlocal",
    wow: "Hyperlocal",
  };

  return bankName[vertical] || "Hyperlocal";
};
