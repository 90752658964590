import { ComponentProps } from "react";
import { Button } from "@hyperlocal/vital2";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { twMerge } from "tailwind-merge";

export const ErrorFeedback = ({
  className,
  children,
  ...rest
}: ComponentProps<"div">) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <div
              className={twMerge(
                "flex h-full flex-1 flex-col items-center justify-center gap-10",
                className,
              )}
              {...rest}
            >
              <h5 className="text-center font-base font-medium" role="alert">
                Houve um problema ao buscar as informações. <br /> Por favor,
                tente novamente.
              </h5>
              <Button.Root onClick={resetErrorBoundary}>
                Tentar novamente
              </Button.Root>
            </div>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
