import {
  MovementTransformed,
  TransactionMovement,
  TransactionType,
} from "@/services/transactions/types";
import { formatToInputDate } from "@/utils/date";

export const CPF_DIGITS = 11;

export const CNPJ_DIGITS = 14;

export const CELLPHONE_DIGITS = 11;

export const URL_PARAMS = {
  pixKey: "pixKey",
  value: "value",
  document: "document",
  type: "type", // ted | pix | p2p
  accountId: "accountId",
  search: "search",
  beneficiary: "beneficiary",
  isFavorite: "isFavorite",
};

export const MASKED_INPUT_DEFAULT_PROPS = {
  autoUnmask: true,
  placeholder: "",
  showMaskOnFocus: false,
  showMaskOnHover: false,
};

export const TODAY = new Date();

export const TODAY_INPUT = formatToInputDate(new Date());

export const CATEGORIES_DICTIONARY: Record<TransactionType, string> = {
  Boleto: "Boleto",
  cartao: "Cartão",
  P2P: "P2P",
  Pix: "PIX",
  TED: "Ted",
};

export const RAW_TYPE_DICTIONARY: Record<TransactionType, string> = {
  Boleto: "Payment",
  cartao: "Cartão",
  P2P: "P2P",
  Pix: "PIX",
  TED: "TED",
};

export const MOVEMENT_DICTIONARY: Record<
  TransactionMovement,
  MovementTransformed
> = {
  IN: "Entrada",
  OUT: "Saída",
};

export const TRANSFER_TED_FEE = 7.6;

export const TRANSFER_PIX_PJ_FEE = 0.89;
