import { queryClient } from "@/lib";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { Routes } from "./routes";
import { GlobalStyle } from "./styles/global";
import { getVertical, themeProvider } from "./utils";
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";

function App() {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <ThemeProvider theme={themeProvider}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            <GlobalStyle />
            <Routes />
            {ReactDOM.createPortal(
              <Toaster position="top-right" />,
              document.body
            )}
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
